<template>
  <transition name="modal" v-if="showModal">
    <div class="modal-mask" @click="toggleModal()">
      <div class="modal-wrapper">
        <div class="modal-container h-50" @click.stop="doNothing()">
          <div class="modal-header">
            <div class="row">
              <div class="modal-header-text col text-start">
                Hartā Bunker Arana
              </div>
              <div @click="toggleModal()" class="col text-end close-button">
                <img :src="require('../assets/svg/close.svg')" width="25" height="25"/>
              </div>
            </div>
          </div>

          <div class="modal-body h-75">
            <div class="row h-100" id="map"></div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import MarkerWithLabel from '@googlemaps/markerwithlabel';

export default {
  name: 'MapModal',
  props: {
    showModalProp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: this.showModalProp,
    };
  },
  methods: {
    doNothing() {
    },
    toggleModal() {
      this.showModal = !this.showModal;
      this.$emit('modalToggled', this.showModal);
    },
    includeScripts(scripts) {
      scripts.forEach((script) => {
        if (!this.isScriptLoaded(script)) {
          const tag = document.createElement('script');
          tag.setAttribute('src', script);
          document.head.appendChild(tag);
        }
      });
    },

    isScriptLoaded(url) {
      const scripts = document.getElementsByTagName('script');
      let i = 0;
      // eslint-disable-next-line no-cond-assign
      for (i = scripts.length; i -= 1;) {
        if (scripts[i].src === url) return true;
      }
      return false;
    },

    initMap() {
      // if (typeof EventTarget !== 'undefined') {
      //   const func = EventTarget.prototype.addEventListener;
      //   EventTarget.prototype.addEventListener = (type, fn, capture) => {
      //     this.func = func;
      //     if (typeof capture !== 'boolean') {
      //       // eslint-disable-next-line no-param-reassign
      //       capture = capture || {};
      //       // eslint-disable-next-line no-param-reassign
      //       capture.passive = false;
      //     }
      //     this.func(type, fn, capture);
      //   };
      // }

      // Create a map object and specify the DOM element for display.
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 44.3174057, lng: 23.7932036 },
        scrollwheel: false,
        zoom: 19,
      });

      // Create a marker and set its position.
      // eslint-disable-next-line no-undef,no-new
      new MarkerWithLabel({
        map,
        position: { lat: 44.31744216098022, lng: 23.793384678388176 },
        labelContent: 'Bunker Arena',
        labelAnchor: new window.google.maps.Point(-40, 20),
        labelClass: 'labels',
        labelStyle: {
          opacity: 1,
        },
        zIndex: 999999,
      });
    },
  },
  watch: {
    showModalProp() {
      this.showModal = this.showModalProp;
    },
    showModal() {
      document.querySelector('body').style.overflow = this.showModal ? 'hidden' : '';
      if (this.showModal === true) {
        window.setTimeout(() => {
          const scripts = [
            'https://maps.googleapis.com/maps/api/js?v=3&amp;&key=AIzaSyCgIyYH2GwS4ZUraGnNnQdQWqnu_oVTXc8',
          ];
          this.includeScripts(scripts);

          this.interval = setInterval(() => {
            if (typeof google !== 'undefined') {
              clearInterval(this.interval);

              this.markerInterval = setInterval(() => {
                if (typeof MarkerWithLabel !== 'undefined') {
                  this.initMap();
                  clearInterval(this.markerInterval);
                }
              }, 100);
            }
          }, 100);
        }, 500);
      }
    },
  },
};
</script>

<style>
  .labels {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    background-color: #21282e;
    padding: 10px;
    white-space: nowrap;
  }
</style>

<style scoped lang="scss">
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    border-radius: 10px;
    width: 70%;
    margin: 0px auto;
    background-color: #21282e;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Raleway,Lato,sans-serif;
  }

  /* For Mobile */
  @media screen and (max-width: 540px) {
    .modal-container {
      width: 90%;
      border-radius: 10px;
      margin: 0px auto;
      background-color: #21282e;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Raleway,Lato,sans-serif;
    }

    .modal-header-text {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;

      font-family: Raleway,Lato,sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  /* For Tablets */
  @media screen and (min-width: 540px) and (max-width: 780px) {
    .modal-container {
      border-radius: 10px;
      width: 90%;
      margin: 0px auto;
      background-color: #21282e;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Raleway,Lato,sans-serif;
    }

    .modal-header-text{
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;

      font-family: Raleway,Lato,sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  .modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #000;
    line-height: 40px;
    height: 40px;
    margin-top: 0;
    background: #FFDC00!important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .price {
    margin-top: 20px;
    background: #FFDC00!important;
    color: #000;
    float: right;
    min-height: 30px;
    line-height: 30px;
    padding: 1px;
    text-align: center;
  }

  .modal-body {
    margin: 20px 0;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }

  .modal-default-button {
    float: right;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .close-button {
    cursor: pointer;
    float:right;
  }

  .shadow-box {
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, .3), 0 0 2px 1px rgba(0, 0, 0, .3) !important;
  }

  .form_input {
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    height: 50px;
    background-color: transparent;
    border: none;
    transition: all .3s ease-in-out;
    border-bottom: 2px solid #949494;
    padding-left: 10px;
    color: white;
    &:focus {
      border-bottom: 2px solid #fff;
      outline: 0;
    }
  }

  .price {
    margin-top: 20px;
    background: #ffdc00!important;
    color: #000;
    float: right;
    min-height: 30px;
    line-height: 30px;
    padding: 1px;
    text-align: center;
  }

  .form_submit {
    color: white;
    border-radius: 0;
    border: none;
    transition: all .3s ease-in-out;
    background-color: #1d2328;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .6), 0 0 2px 1px rgba(0, 0, 0, .45);
  }
</style>
